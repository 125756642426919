import axios from "axios";

import Cookies from "js-cookie";

const AccessTokenKey = "ac_tk";

export const getHeaders = () => {
  const token = Cookies.get(AccessTokenKey);

  if (token) {
    return {
      Accept: "application/json",
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  return {
    Accept: "application/json",
    "content-type": "application/json",
  };
};

export const getProducts = async (query: {
  restaurant_id: string;
  limit: number;
  offset: number;
}) => {
  try {
    const { data } = await axios.get(
      `https://hvgl.app/api/product`,
      {
        headers: getHeaders(),
        params: query,
      }
    );

    return data?.data;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return {
      total: 0,
      records: [],
    };
  }
};
