"use client";
import Image from "next/image";
import css from "./styles.module.scss";
import ArrowLeft from "../../../../assets/images/arrow-left.svg";
import ArrowRight from "../../../../assets/images/arrow-right.svg";
import { useRef, useState } from "react";
import clsx from "clsx";
import { Playfair } from "@/app/layout";
import { Carousel } from "antd";

type CarouselItem = {
  image: string;
  title: string;
  video: string;
  id: string;
};

export const Component5 = () => {
  const [videoSelected, setVideoSelected] = useState<CarouselItem>({
    image: "https://www.youtube.com/embed/Pbgxn5yiH2M?si=u3sr1u1wxghVWMMG",
    title: "Introduction",
    video: "",
    id: "1",
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);

  if (!ref) return;
  const data = [
    {
      image: "https://www.youtube.com/embed/sYATMA5-LFg?si=Ism2Z_WDx4w0aELi",
      title: "Introduction",
      video: "",
      id: "1",
    },
    {
      image: "https://www.youtube.com/embed/Pbgxn5yiH2M?si=u3sr1u1wxghVWMMG",
      title: "Pudding",
      video: "",
      id: "2",
    },
    {
      image: "https://www.youtube.com/embed/eoSG6r41llw?si=-HIg6rRp7_MZ6Q0J",
      title: "Tea leaf",
      video: "",
      id: "3",
    },
    {
      image: "https://www.youtube.com/embed/VyZno4X-8UM?si=DYXShdMZmbpPjF58",
      title: "Power",
      video: "",
      id: "4",
    },
    {
      image: "https://www.youtube.com/embed/VyZno4X-8UM?si=dAagauxL2PS0xhr2",
      title: "Introduction",
      video: "",
      id: "5",
    },
  ];
  return (
    <div className={css.Wrapper}>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        src={videoSelected.image}
        className={css.Background}
      />

      <div className={css.CarouselWrapper}>
        <Carousel
          infinite
          autoplay
          style={{
            gap: 24,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          dots={false}
          ref={ref}
          slidesToShow={4}
        >
          {data.map((item, index) => (
            <div
              onClick={() => setVideoSelected(item)}
              className={css.SliderWrapper}
              key={index}
            >
              <iframe src={item.image} className={clsx(css.ImageSlider)} />
              <span className={clsx(css.ItemTitle, Playfair.className)}>
                {item.title}
              </span>
            </div>
          ))}
        </Carousel>
        <div
          onClick={() => {
            ref.current.prev();
          }}
          className={css.ButtonLeft}
        >
          <Image width={20} height={20} src={ArrowLeft} alt="Arrow Left" />
        </div>
        <div
          onClick={() => {
            ref.current.next();
          }}
          className={css.ButtonRight}
        >
          <Image width={20} height={20} src={ArrowRight} alt="Arrow Right" />
        </div>
      </div>
    </div>
  );
};
